import { writable, type Writable } from 'svelte/store'

export type PageStatus = Writable<{
	map: {
		[key: string]: string[]
	}
	state: string | undefined
	load: boolean | undefined
}>
export const pageStatus:PageStatus = writable({
	map: {
		dev: []
	},
	state: undefined,
	load: undefined
})

